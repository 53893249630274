import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './layout.module.scss';
import Header from '../header';
import Footer from '../footer';

export function Layout() {
    return(
        <div className={styles.container}>
            <Header/>
            <div className="outlet">
                <Outlet />
            </div>
            <Footer />
        </div>
    )
}