import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import Lightning from '../../../../icons/electric.png';

export function TailorMadeDesigns() {

    return(
        <div className={`rounded-card ${styles.cardContainer}`}>
            <div className={`rounded-card ${styles.childCard}`}>
                <div className={styles.iconContainer}>
                    <Image src={Lightning} fluid alt="lightning-icon" />
                </div>
                <div className={styles.childCardChild}>
                    <div className={styles.childCardChildSub}>
                        <span className={styles.dot}></span>
                        <div className={styles.dotText}>NEW</div>
                    </div>
                    <h6>Latest design</h6>
                    <p>Today, 11:50</p>
                </div>
            </div>
            <h3>Skreddersydd design</h3>
            <p>
                Vi har ekspertisen til å gjøre din visjon til virkelighet.
            </p>
        </div>
    )
}