import React, { useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import VP from '../../../logos/verktoypartner-as-3c8594f4.png';
import limit from '../../../logos/limitless.png';
import bona from '../../../logos/Bonaventura-Logo.png';
import Image from 'react-bootstrap/Image';
import gymF from '../../../logos/gymfluence.png';
import flyet from '../../../logos/flyet.png';
import simple from '../../../logos/simple-logo.png';
import mila from '../../../logos/mila.png';

export default function Partners() {
    const scrollRef = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollRef.current;
        const scrollSpeed = 1; // Antall piksler per intervall
        const interval = 15; // Tidsintervall i millisekunder

        const autoScroll = setInterval(() => {
            if (scrollContainer) {
                scrollContainer.scrollLeft += scrollSpeed;

                // Hvis vi når slutten, hopp tilbake til starten (sømløst)
                if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
                    scrollContainer.scrollLeft = 0;
                }
            }
        }, interval);

        return () => clearInterval(autoScroll); // Rydd opp når komponenten unmountes
    }, []);

    return (
        <div className={styles.container} ref={scrollRef}>
            <div className={styles.cardContainer}>
                {/* De originale kortene */}
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={bona} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={limit} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={gymF} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={simple} />
                </div>
                <div className={styles.card}>
                    <Image alt="logo" fluid src={flyet} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={mila} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={VP} />
                </div>

                {/* Klonede kort for sømløs looping */}
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={bona} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={limit} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={gymF} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={simple} />
                </div>
                <div className={styles.card}>
                    <Image alt="logo" fluid src={flyet} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={mila} />
                </div>
                <div className={styles.card}>
                    <Image className="rounded-card" alt="logo" fluid src={VP} />
                </div>
            </div>
        </div>
    );
}
