import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import phone from '../../../../icons/iphone.png';
import tablet from '../../../../icons/tablet.png';
import mac from '../../../../icons/mac.png';

export function Responsivitet() {

    return(
        <div className={`rounded-card ${styles.cardContainer}`}>
            <div className={`rounded-card ${styles.childCard}`}>
                <div className={styles.iconContainer}>
                    <div className={styles.iconChild}>
                        <Image src={phone} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={tablet} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={mac} fluid alt="lightning-icon" />
                    </div>
                </div>
            </div>
            <h3>Responsivitet</h3>
            <p>
                Responsive apper og nettsider som fungerer sømløst på alle enheter.
            </p>
        </div>
    )
}