import React from 'react';
import { ContactUs } from '../../form';
import styles from './styles.module.scss';

export function ContactPage() {
    return(
        <div className={`home-container ${styles.container}`}>
            <ContactUs />
        </div>
    )
}