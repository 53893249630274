import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import cross from '../../../../icons/cross-functional.png';
import prefrences from '../../../../icons/preferences.png';
import functional from '../../../../icons/functional.png';

export function SuperiorFunctionality() {

    return(
        <div className={`rounded-card ${styles.cardContainer}`}>
            <div className={`rounded-card ${styles.childCard}`}>
                <div className={styles.iconContainer}>
                    <div className={styles.iconChild}>
                        <Image src={cross} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={functional} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={prefrences} fluid alt="lightning-icon" />
                    </div>
                </div>
            </div>
            <h3>Overlegen funksjonalitet</h3>
            <p>
                Enten vi skal tilpasse rammeverk eller bygge fra bunnen garanterer vi overlegen funksjonalitet.
            </p>
        </div>
    )
}