import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import star from '../../../icons/star.png';
import Accordion from '../../accordion';
import XD from '../../../logos/adobe_xd.jpg';
import figma from '../../../logos/figma-logo-512.webp';
import react from '../../../logos/react.png';
import native from '../../../logos/reactjs_javascript_library_atom_atomic_react-512.webp';
import supabase from '../../../logos/supabase-icon9119.logowik.com.webp';
import GoogleAnalytics from '../../../logos/Google-Analytics-Logo.png';
import Shopify from '../../../logos/images (3).png';
import strapi from '../../../logos/strapi.png';
import WordPress from '../../../logos/wordpress.png';
import Zapier from '../../../logos/zapier-logo-46EEE9963E-seeklogo.com.png';
import Trello from '../../../logos/trello-logo-CE7B690E34-seeklogo.com.png'

export function ToolsWeUse() {
    return(
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.iconContainer}>
                    <Image fluid alt="icon" src={star} />
                </div>
                <h3 className={styles.overskrift}>Noen av verktøyene vi bruker</h3>
            </div>
            <Accordion 
                    title="Adobe XD"
                    content="Adobe XD er et verktøy for å designe og prototype brukergrensesnitt (UI) og brukeropplevelser (UX). 
                    Det brukes til å lage interaktive mockups som effektivt visualiserer produktdesign før implementering."
                    icon={XD}
                />
            <Accordion 
                title="Figma"
                content="Figma er et samarbeidsbasert designverktøy for UI/UX som gjør det enkelt å utvikle interaktive prototyper. 
                Det muliggjør samarbeid i sanntid mellom teammedlemmer, noe som effektiviserer designprosessen."
                icon={figma}
            />
            <Accordion 
                title="Trello"
                content="Trello er et visuelt verktøy for prosjektstyring som gjør det enkelt å organisere og følge opp oppgaver. 
                Med sitt brukervennlige tavlesystem er det perfekt for å administrere alt fra enkle til komplekse prosjekter."
                icon={Trello}
            />
            <Accordion 
                title="React"
                content="React er et kraftig JavaScript-bibliotek for å bygge brukervennlige, interaktive brukergrensesnitt i 
                nettsider og applikasjoner. Det lar utviklere lage dynamiske og raskt reagerende applikasjoner med effektiv datahåndtering."
                icon={react}
            />
            <Accordion 
                title="ReactNative"
                content="React Native er et rammeverk for å bygge mobilapplikasjoner ved bruk av React, men som leverer native brukeropplevelser 
                på både iOS og Android. Det gjør det mulig å utvikle én kodebase som fungerer på flere plattformer."
                icon={native}
            />
            <Accordion 
                title="Supabase"
                content="Supabase er en open-source backend-løsning som gir umiddelbar tilgang til en database og autentiseringssystem. Det 
                brukes til raskt å bygge og skalere fullstack-applikasjoner uten å administrere servere."
                icon={supabase}
            />
            <Accordion 
                title="Strapi"
                content="Strapi er et headless CMS som gir fleksibilitet i å administrere innhold uavhengig av frontend. Det brukes for å bygge 
                skalerbare og dynamiske applikasjoner med full kontroll over hvordan innholdet distribueres."
                icon={strapi}
            />
            <Accordion 
                title="WordPress"
                content="WordPress er et fleksibelt og populært innholdsstyringssystem som gjør det enkelt å bygge og vedlikeholde nettsider. 
                Det brukes av både små bedrifter og store organisasjoner for å administrere alt fra blogger til komplekse nettsteder."
                icon={WordPress}
            />
            <Accordion 
                title="Zapier"
                content="Zapier er et automatiseringsverktøy som kobler sammen ulike applikasjoner og tjenester for å utføre repetitive oppgaver automatisk. 
                Det brukes til å effektivisere arbeidsflyter uten behov for kode."
                icon={Zapier}
            />
            <Accordion 
                title="Google Analytics"
                content="Google Analytics er et verktøy som sporer og rapporterer nettrafikk, noe som gir dyp innsikt i 
                brukeradferd og markedsføringskampanjer. Det brukes til å optimalisere nettsider basert på dataanalyse og brukermønstre."
                icon={GoogleAnalytics}
            />
            <Accordion 
                title="Shopify"
                content="Shopify er en alt-i-ett e-handelsplattform som gjør det enkelt å opprette og administrere nettbutikker. Den støtter 
                salg via flere kanaler og er ideell for rask lansering og skalering av nettbaserte forretninger."
                icon={Shopify}
            />
        </div>
    )
}