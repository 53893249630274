import React from 'react';
import ScrollingComponent from '../../scrollComponent';

export default function PrivacyDagensOrd() {
    return(<div className="App col-11 col-md-6 m-auto">
        <h1>Personvernerklæring for Dagens Ord App </h1>
  
  <p>Sist oppdatert: 18/9/24</p>
  
  <h5>Dagens Ord App er forpliktet til å beskytte personvernet til brukerne av appen vår. Denne personvernerklæringen forklarer hvordan vi håndterer personopplysninger og annen informasjon som samles inn når du bruker appen.</h5>
  <br></br>
  <br></br>
  <h2>1. Ingen innsamling av personopplysninger</h2>
  Vi samler ikke inn noen personopplysninger fra deg når du bruker Dagens Ord App. Dette inkluderer, men er ikke begrenset til, navn, e-postadresser, telefonnumre eller lokasjonsdata.
  <br></br>
  <br></br>
  <h2>2. Ingen bruk av cookies</h2>
  Appen vår bruker ikke cookies eller lignende teknologier for å spore din brukeratferd. Vi samler ikke inn informasjon om hvordan du bruker appen, og vi deler ikke noe informasjon med tredjeparter.
  <br></br>
  <br></br>
  <h2>3. Tredjeparts tjenester</h2>
  Dagens Ord App bruker ikke noen tredjeparts tjenester som kan samle inn data om deg.
  <br></br>
  <br></br>
  <h2>4. Sikkerhet</h2>
  Selv om vi ikke samler inn informasjon, er vi forpliktet til å sikre at eventuelle data som behandles gjennom appen er trygt og sikkert, og vi følger relevante sikkerhetsprotokoller for å beskytte informasjonen du deler med oss via andre kanaler.
  <br></br>
  <br></br>
  <h2>5. Endringer i denne personvernerklæringen</h2>
  Vi kan oppdatere denne personvernerklæringen fra tid til annen. Når vi gjør det, vil vi oppdatere datoen øverst i denne erklæringen. Vi oppfordrer deg til å gjennomgå denne erklæringen regelmessig for å holde deg informert om hvordan vi beskytter ditt personvern.
  <br></br>
  <br></br>
  <h2>6. Kontaktinformasjon</h2>
  Hvis du har spørsmål om denne personvernerklæringen, vennligst kontakt oss på:<br></br> ole-a@braenna.com
  <br></br>
  <br></br>
  
  
      </div>)
}

export function Privacy() {
    return(
    <div className="App col-11 col-md-6 m-auto">
        <div className="home-container">
            <h1>Personvernerklæring for Brænna Consult </h1>
    
            <p>Sist oppdatert: 3/10/24</p>
            
            <h5>Brænna Consult er forpliktet til å beskytte personvernet til brukerne av nettsiden vår. Denne personvernerklæringen forklarer hvordan vi håndterer personopplysninger og annen informasjon som samles inn når du bruker nettsiden.</h5>
            <br></br>
            <br></br>
            <h2>1. Ingen innsamling av personopplysninger</h2>
            Vi samler ikke inn noen personopplysninger fra deg når du bruker denne siden. Dette inkluderer, men er ikke begrenset til, navn, e-postadresser, telefonnumre eller lokasjonsdata.
            <br></br>
            <br></br>
            <h2>2. Ingen bruk av cookies</h2>
            Siden vår bruker ikke cookies eller lignende teknologier for å spore din brukeratferd. Vi samler ikke inn informasjon om hvordan du bruker siden, og vi deler ikke noe informasjon med tredjeparter.
            <br></br>
            <br></br>
        </div>
    <ScrollingComponent containerName="container-1">
        <h2>3. Tredjeparts tjenester</h2>
        Brænna Consult bruker ikke noen tredjeparts tjenester som kan samle inn data om deg.
        <br></br>
        <br></br>
        <h2>4. Sikkerhet</h2>
        Selv om vi ikke samler inn informasjon, er vi forpliktet til å sikre at eventuelle data som behandles gjennom appen er trygt og sikkert, og vi følger relevante sikkerhetsprotokoller for å beskytte informasjonen du deler med oss via andre kanaler.
        <br></br>
        <br></br>
    </ScrollingComponent>
    <ScrollingComponent containerName="container-2">
        <h2>5. Endringer i denne personvernerklæringen</h2>
        Vi kan oppdatere denne personvernerklæringen fra tid til annen. Når vi gjør det, vil vi oppdatere datoen øverst i denne erklæringen. Vi oppfordrer deg til å gjennomgå denne erklæringen regelmessig for å holde deg informert om hvordan vi beskytter ditt personvern.
        <br></br>
        <br></br>
        <h2>6. Kontaktinformasjon</h2>
        Hvis du har spørsmål om denne personvernerklæringen, vennligst kontakt oss på:<br></br> ole-a@braenna.com
        <br></br>
        <br></br>
    </ScrollingComponent>
      </div>)
}