import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import fCss from './fCss.module.scss';
import CustomAlert from '../customModal';

export const ContactUs = () => {

    // const [isVisible, setIsVisible] = useState(false);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const container = document.querySelector(`.${fCss.container}`);
    //         const containerTop = container.getBoundingClientRect().top;
    //         const windowHeight = window.innerHeight;
    //         const threshold = (windowHeight / 100) * 65;

    //         if (containerTop <= threshold) {
    //             setIsVisible(true);
    //         } else {
    //             setIsVisible(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const [alertData, setAlertData] = useState({
        show: false,
        message: '',
        isError: false,
    });

    const redirectUser = () => {
        window.location.reload();
    }

    const handleAlertClose = () => {
        setAlertData({show:false, message:'', isError:false});
    }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_orodo6s', 'template_cjax8vm', form.current, {
        publicKey: 'AO8bro97SCGDLAJu4',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setAlertData({
            show: true,
            message: 'Meldingen din ble sendt! Jeg svarer deg så fort som mulig!'
          })
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div className={`rounded-card ${fCss.container}`}>
            <h2 className="text-secondary">KONTAKT</h2>
            <div className={fCss.container3}>
                <p>
                    {/* Har du lyst på et uforpliktende tilbud eller en hyggelig prat for å se om
                    jeg kan hjelpe akkurat deg eller ditt firma? */}
                    {/* Er du nysgjerrig på hva jeg kan hjelpe til med? Ta kontakt for en uforpliktende 
                    og hyggelig samtale så vi kan snakke litt om dine ønsker og behov, og finne ut av om vi 
                    kan hjelpe akkurat deg eller ditt firma!
                    <br></br>
                    <br></br> */}
                    Fyll ut skjemaet under, så tar jeg kontakt med deg i løpet av 24 timer!</p>
            </div>
            <form className={fCss.form} ref={form} onSubmit={sendEmail}>
                <label>Navn</label>
                <input type="text" name="user_name" required/>
                <label>E-mail</label>
                <input type="email" name="user_email" required/>
                <label>Melding</label>
                <textarea name="message" required/>
                <button type="submit" value="Send">Send</button>
            </form>
            <CustomAlert 
                show={alertData.show}
                handleClose={handleAlertClose}
                message={alertData.message}
                redirectUser={redirectUser}
                buttonLabel="Jeg forstår!"
            />
        </div>
  );
};