import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import star from '../../icons/star.png';

export default function StarHeader({title}) {
    return(
            <div className={styles.subHeader}>
                <div className={styles.starContainer}>
                    <Image fluid alt="star-icon" src={star} />
                </div>
                <div className={styles.overskrift}>{title}</div>
            </div>
    )
}