import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import star from '../../../icons/star.png';
import CardAccordion from '../../cardAccordion';

export function FAQs() {
    return(
        <div className={styles.container}>
            {/* <div className={styles.header}>
                <div className={styles.iconContainer}>
                    <Image fluid alt="icon" src={star} />
                </div>
                <h3 className={styles.overskrift}>FAQ's</h3>
            </div> */}
            <div className={styles.overskrift2}>Vi har svarene</div>
                <CardAccordion 
                    title="Hvilke tjenester tilbyr Brænna Consult?"
                    content="Bræænna Consult tilbyr et bredt spekter av IT-tjenester, 
                    inkludert systemutvikling, app- og web-utvikling, skybaserte løsninger, 
                    sikkerhetsrådgivning, prosjektledelse og teknisk support."
                />
                <CardAccordion 
                    title="Hvor lang tid tar det?"
                    content="Hvor lang tid et prosjekt tar avhenger av flere variabler. Kompleksitet,
                    funksjonalitet og størrelsen på prosjektet vil være med å bestemme hvor lang tid vi estimerer."
                />
                <CardAccordion 
                    title="Kan du tilpasse løsninger til våre spesifikke behov?"
                    content="Absolutt! Brænna Consult spesialiserer seg på å forstå kundens unike krav og skreddersyr 
                    løsninger som passer både teknisk og forretningsmessig."
                />
        </div>
    )
}

export function FAQs2() {
    return(
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.iconContainer}>
                    <Image fluid alt="icon" src={star} />
                </div>
                <h3 className={styles.overskrift}>FAQ's</h3>
            </div>
            <div className={styles.overskrift2}>Vi har svarene</div>
                <CardAccordion 
                    title="Hvilke tjenester tilbyr Brænna Consult?"
                    content="Bræænna Consult tilbyr et bredt spekter av IT-tjenester, 
                    inkludert systemutvikling, app- og web-utvikling, skybaserte løsninger, 
                    sikkerhetsrådgivning, prosjektledelse og teknisk support."
                />
                <CardAccordion 
                    title="Hvor lang tid tar det?"
                    content="Hvor lang tid et prosjekt tar avhenger av flere variabler. Kompleksitet,
                    funksjonalitet og størrelsen på prosjektet vil være med å bestemme hvor lang tid vi estimerer."
                />
                <CardAccordion 
                    title="Kan du tilpasse løsninger til våre spesifikke behov?"
                    content="Absolutt! Brænna Consult spesialiserer seg på å forstå kundens unike krav og skreddersyr 
                    løsninger som passer både teknisk og forretningsmessig."
                />
                3. Hvilke teknologier og plattformer jobber du med?
Jeg har erfaring med teknologier som React, React Native, WordPress, Supabase, Figma, Shopify og flere skyplattformer som AWS, Azure og Google Cloud.

4. Hvordan kan du hjelpe min bedrift med å bli mer effektiv?
Jeg hjelper bedrifter med å optimalisere sine teknologiske prosesser, implementere automatiseringer, integrere verktøy som øker produktiviteten, og sikre en smidigere drift.

5. Hvilken type prosjekter har du jobbet med tidligere?
Jeg har jobbet med alt fra små apper til større systemintegrasjoner, e-handelsplattformer, nettsidedesign og utvikling av skreddersydde IT-løsninger for bedrifter.

6. Hva er din tilnærming til prosjektstyring?
Jeg bruker agile metoder som Scrum og Kanban, tilpasset prosjektets størrelse og behov, og jobber tett med kunden for å sikre jevn fremdrift og tydelig kommunikasjon.

7. Hvor mye koster dine tjenester?
Prisene mine varierer basert på prosjektets omfang og kompleksitet. Jeg tilbyr fleksible betalingsmodeller, inkludert fastpris og timebasert fakturering, tilpasset kundens preferanser.

8. Hva er din erfaring med nettsikkerhet?
Jeg har erfaring med å identifisere og løse sikkerhetsproblemer, implementere sikre systemer og tilby rådgivning rundt sikkerhetsstrategier, inkludert GDPR-compliance og sikkerhet i nettskyen.

9. Tilbyr du support etter prosjektets slutt?
Ja, jeg tilbyr både vedlikeholds- og supportavtaler for å sikre at løsningene fortsetter å fungere optimalt etter implementering.

10. Hvordan foregår samarbeidet underveis i prosjektet?
Jeg jobber tett med kundene gjennom hele prosjektets livssyklus, gir regelmessige oppdateringer og sørger for at vi er på samme side når det gjelder tidsfrister og krav.

11. Hvordan håndterer du tidsfrister og prosjektrammer?
Jeg legger stor vekt på å levere innen avtalt tid og budsjett. Gjennom god planlegging og kontinuerlig kommunikasjon sikrer jeg at prosjektet holder fremdriften.

12. Kan du hjelpe oss med å velge riktig teknologi for vårt prosjekt?
Ja, jeg hjelper gjerne med å velge teknologier som passer best til deres behov, både nåværende og fremtidige, for å sikre skalerbarhet og effektivitet.
        </div>
    )
}