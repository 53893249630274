import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Privacy } from './components/pages/privacy';
import { Layout } from './components/layout';
import Home from './components/pages/home';
import { ContactPage } from './components/pages/contact';
import PrivacyDagensOrd from './components/pages/privacy';

function App() {
  return (
    <Routes>
      <Route  path="/" element={<Layout />}>
        <Route index element={<Home />}/>
        <Route path="privacy/dagensOrd" element={<PrivacyDagensOrd />}/>
        <Route path="privacy/" element={<Privacy />}/>
        <Route path="contact" element={<ContactPage />}/>
      </Route>
    </Routes>
  );
}

export default App;
