import React from 'react';
import Modal from 'react-bootstrap/Modal';
import modalCss from './modal.module.css';

function CustomAlert({ show, handleClose, message, buttonLabel, submit, redirectUser, modalTitle }) {
    const handleButtonClick = () => {
      if(submit) {
        submit();
      }
      handleClose();
      if(redirectUser) {
          redirectUser();
      }
    }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className={modalCss.title}>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={modalCss.body}>{message}</Modal.Body>
      <Modal.Footer>
        <button onClick={handleButtonClick}
          className={modalCss.button}>
            {buttonLabel}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomAlert;