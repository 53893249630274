import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import framework1 from '../../../../icons/framework (1).png';
import framework2 from '../../../../icons/framework.png';
import tools from '../../../../icons/support.png';

export function UpToDate() {

    return(
        <div className={`rounded-card ${styles.cardContainer}`}>
            <div className={`rounded-card ${styles.childCard}`}>
                <div className={styles.iconContainer}>
                    <div className={styles.iconChild}>
                        <Image src={tools} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={framework1} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={framework2} fluid alt="lightning-icon" />
                    </div>
                </div>
            </div>
            <h3>De nyeste verktøyene</h3>
            <p>
                Vi strever etter å alltid være oppdatert på det siste innen programmering, design, 
                automatisering, onboarding mm.
            </p>
        </div>
    )
}