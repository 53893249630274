import React, { useState } from 'react';
import styles from './styles.module.css';

const CardAccordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.accordionItem} corner ${isOpen ? styles.open : ''}`}>
      <div className={styles.accordionHeader} onClick={toggleAccordion}>
        <div className={styles.accordionTitle}><h4>{title}</h4></div>
        <div className={`${styles.accordionIconRight} ${isOpen ? styles.open : ''}`}>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`${styles.accordionContent} ${isOpen ? styles.open : ''}`}>
        {content}
      </div>
    </div>
  );
};

export default CardAccordion;
