import React from 'react';
import styles from './footer.module.scss';

export default function Footer() {

    const redirectToContact = () => {
        window.location.href="/contact";
    }

    const redirectToPrivacy = () => {
        window.location.href="/privacy";
    }

    return(
        <div className="home-container">
            <div className={styles.container}>
                <div className={styles.childParent}>
                    <div className={styles.child} onClick={redirectToContact}>Kontaktinformasjon</div>
                    <div className={styles.child} onClick={redirectToPrivacy}>Personvernerklæring</div>
                </div>
                <div className={styles.madeBy}>Laget av Ole-Aleksander Brænna for Brænna Consult</div>
            </div>
        </div>
    )
}