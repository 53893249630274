import React from 'react';
import Intro from '../../paragraphs/intro';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import img1 from '../../../images/me.jpg';
import ScrollingComponent from '../../scrollComponent';
import { TailorMadeDesigns } from '../../paragraphs/cards/tailor-made-design';
import { ContinousSupport } from '../../paragraphs/cards/continous support';
import { UpToDate } from '../../paragraphs/cards/UpToDate';
import { HowItWorks } from '../../paragraphs/how_it_works';
import { ContactUs } from '../../form';
import { SuperiorFunctionality } from '../../paragraphs/cards/superior-functionality';
import Partners from '../../paragraphs/partners';
// import star from '../../../icons/star.png';
import { ToolsWeUse } from '../../paragraphs/tools';
import { FAQs } from '../../paragraphs/FAQ\'s';
import { CustomerSuccess, CustomerSuccess2, CustomerSuccess3 } from '../../paragraphs/customerSuccess';
//import ScrollingPair from '../../trippleScroll';
import ScrollingTrio from '../../trippleScroll';
import StarHeader from '../../starHeader';
import { Responsivitet } from '../../paragraphs/cards/responsivitet';
import { HappyCustomers } from '../../paragraphs/cards/happy customers';
//import ScrollFromRight from '../../scrollFromRight';

export default function Home() {
    return(
        <div className={styles.homeContainer}>
            <div className={styles.homeSub}>
                <div className={`${styles.imageContainer} home-container`}>
                    <Image className="running" src={img1} fluid alt="image of founder"/>
                </div>
                <div className={`${styles.container} appearing-container`}>
                    <Intro />
                </div>
            </div>
            {/* <ScrollingComponent containerName="scroll-container-10"> */}
            <div className="appearing-container">
                <StarHeader title="Allerede valgt av" />
                <Partners />
            </div>
                
            {/* </ScrollingComponent> */}
            <ScrollingComponent containerName="scroll-container-13">
                <StarHeader title="Hva vi tilbyr" />
            </ScrollingComponent>
            <ScrollingTrio containerName="pari-1">
                <ScrollingComponent containerName="scroll-container-1">
                    <TailorMadeDesigns />
                </ScrollingComponent>
                <ScrollingComponent containerName="scroll-container-15">
                    <Responsivitet />
                </ScrollingComponent>
                <ScrollingComponent containerName="scroll-container-6">
                    <SuperiorFunctionality />
                </ScrollingComponent>
            </ScrollingTrio>
            <ScrollingTrio containerName="pair-2">
                <ScrollingComponent containerName="scroll-container-3">
                    <UpToDate />
                </ScrollingComponent>
                <ScrollingComponent containerName="scroll-container-2">
                    <ContinousSupport />
                </ScrollingComponent>
                <ScrollingComponent containerName="scroll-container-16">
                    <HappyCustomers />
                </ScrollingComponent>
            </ScrollingTrio>
            <ScrollingComponent containerName="scroll-container-14">
                <StarHeader title="Hvordan det funker" />
            </ScrollingComponent>
            <ScrollingComponent containerName="scroll-container-4">
                <HowItWorks />
            </ScrollingComponent>
            <ScrollingComponent containerName="scroll-container-12">
                <StarHeader title="Kundeomtaler" />
            </ScrollingComponent>
            <ScrollingTrio containerName="pair-3">
                <ScrollingComponent containerName="scroll-container-9">
                    <CustomerSuccess />
                </ScrollingComponent>
                <ScrollingComponent containerName="scroll-container-11">
                    <CustomerSuccess2 />
                </ScrollingComponent>
                <ScrollingComponent containerName="scroll-container-18">
                    <CustomerSuccess3 />
                </ScrollingComponent>
            </ScrollingTrio>
            <ScrollingComponent containerName="scroll-container-17">
                <StarHeader title="FAQ's" />
            </ScrollingComponent>
            <ScrollingComponent containerName="scroll-container-8">
                    <FAQs />
                </ScrollingComponent>
            <ScrollingComponent containerName="scroll-container-5">
                <ContactUs />
            </ScrollingComponent>
            <ScrollingComponent containerName="scroll-container-7">
                <ToolsWeUse />
            </ScrollingComponent>
        </div>
    )
}