import React from 'react';
import styles from './styles.module.scss';

export default function Intro() {
    return(
        <div className="text-center">
            <h1 className={styles.overskrift}>Hei! Jeg heter Ole-Aleksander, velkommen til Brænna Consult</h1>
            <p className="p-margin-top">Brænna Consult har som visjon å levere alt av IT-tjenester til små og
                store bedrifter.
            Vi tilbyr alt fra moderne designede nettsider, til komplekse bookingsystemer og avanserte apper.</p>
        </div>
    )
}