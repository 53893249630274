import React from 'react';
import styles from './styles.module.scss';
// import Image from 'react-bootstrap/Image';
// import star from '../../../icons/star.png';
import Accordion from '../../accordion';
import phone from '../../../icons/phone-call.png';
import planning from '../../../icons/project-management.png';
import roadmap from '../../../icons/roadmap.png';
import build from '../../../icons/build.png';
import puzzle from '../../../icons/puzzle.png';

export function HowItWorks() {
    return(
        <div className={styles.container}>
            <Accordion 
                    title="Ta kontakt"
                    content="Ta kontakt med oss gjennom kontaktskjemaet
                    eller via mail, LinkedIn eller ring eller send en tekstmelding!
                    Vi er tilgjengelig alle dager, hele uken og svarer deg alltid i løpet av 24 timer."
                    icon={phone}
                />
            <Accordion 
                title="Planlegging"
                content="Du deler din visjon og vi lager en detaljert plan på hvordan vi skal komme oss dit.
                Vi finner sammen ut av hvilke verktøy som trengs, hvilke rammeverk som skal benyttes og når det skal leveres."
                icon={planning}
            />
            <Accordion 
                title="Prototyping"
                content="Ved hjelp av topp moderne prototyping-verktøy som Adobe XD eller Fimga lager vi en prototype
                mens du lener deg tilbake og slapper av."
                icon={roadmap}
            />
            <Accordion 
                title="Lansering"
                content="Vi bygger og ferdigstiller prosjektet som avtalt, med kontinuerlig kommunikasjon, oppdateringer og
                tilbakemeldinger underveis. Etter lansering fortsetter vi naturligvis å være tilgjengelig for utbedringer,
                oppdatering og hva enn du måtte ønske."
                icon={build}
            />
            <Accordion 
                title="Skalering"
                content="Trenger du å skalere opp? Vi følger deg hele veien og tilrettelegger for skalering fra første stund,
                noe som gjør at når det trengs å skaleres opp for flere brukere/besøkende etc er vi allerede klare for dette."
                icon={puzzle}
            />
        </div>
    )
}