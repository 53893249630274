import React from 'react';
import styles from './styles.module.scss';
import Image from 'react-bootstrap/Image';
import phone from '../../../../icons/phone-call.png';
import letter from '../../../../icons/email.png';
import mac from '../../../../icons/imac.png';

export function ContinousSupport() {

    return(
        <div className={`rounded-card ${styles.cardContainer}`}>
            <div className={`rounded-card ${styles.childCard}`}>
                <div className={styles.iconContainer}>
                    <div className={styles.iconChild}>
                        <Image src={phone} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={letter} fluid alt="lightning-icon" />
                    </div>
                    <div className={styles.iconChild}>
                        <Image src={mac} fluid alt="lightning-icon" />
                    </div>
                </div>
            </div>
            <h3>Kontinuerlig support</h3>
            <p>
                Vi jobber tett og hands-on med alle våre klienter, og er tilgjengelig 24/7.
            </p>
        </div>
    )
}