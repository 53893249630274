import React from 'react';
import LLapp from '../../../images/Group 5.png';
import Image from 'react-bootstrap/Image';
import styles from './styles.module.scss';
import Peter from '../../../images/customerSuccess/peter.png';
import Bona from '../../../images/customerSuccess/bonaventura.png';

export function CustomerSuccess() {

    return(
        <div className={styles.container}>
            <h2>Limitless Coaching App</h2>
            <p>"Det har vært en glede å samarbeide med Ole-Aleksander på vårt siste prosjekt.
                <br></br>
                <br></br>
                Takket være god kommunikasjon og tett oppfølging gjennom hele prosessen sitter vi nå igjen
                med et produkt vi virkelig kan være stolte av, og vi nøler ikke ett sekund med
                å anbefale Brænna Consult til andre som trenger apper eller andre it-tjenester!"
            </p>
            <span className={styles.starFilled}>
                &#9733; &#9733; &#9733; &#9733; &#9733;
            </span>
            <div className={styles.p2}>Oskar Miszta, eier og grunnlegger av Limitless Coaching</div>
            <div className={styles.imageContainer}>
                <Image fluid alt="Limitless app" src={LLapp} />
            </div>
        </div>
    )
}

export function CustomerSuccess2() {

    return(
        <div className={styles.container}>
            <h2>Yogaskolen</h2>
            <p>"Ole-Aleksander har en enestående evne til å løse problemer på en god og effektiv måte.
                <br></br>
                <br></br>
                I tilegg er han utrolig imøtekommende, og enkel å samarbeide med. 
                <br></br>
                <br></br>
                Anbefales!"
            </p>
            <span className={styles.starFilled}>
                &#9733; &#9733; &#9733; &#9733; &#9733;
            </span>
            <div className={styles.p2}>Peter Østergaard, Instruktør og grunnlegger av Yogaskolen</div>
            <div className={styles.imageContainer2}>
                <Image fluid alt="Limitless app" src={Peter} />
            </div>
        </div>
    )
}

export function CustomerSuccess3() {

    return(
        <div className={styles.container}>
            <h2>Bonaventura Scandza</h2>
            <p>"Vår erfaring med Brænna Consult er A+.
                <br></br>
                <br></br>
                Åpen kommunikasjon, rask leveringstid og et ferdig produkt som overgikk forventningene!
            </p>
            <span className={styles.starFilled}>
                &#9733; &#9733; &#9733; &#9733; &#9733;
            </span>
            <div className={styles.p2}>Malin Innhaug, Principal Commercial Manager, Bonaventura Scandza</div>
            <div className={styles.imageContainer2}>
                <Image fluid alt="Limitless app" src={Bona} />
            </div>
        </div>
    )
}